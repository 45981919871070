import { usePage as usePageDefault } from '@inertiajs/vue3'
import { computed } from 'vue'

import type { NavigationItem, User } from '@/Types'

// These are the inertia page props from @inertiajs/core
type PageProps = Record<string, unknown>

export interface SharedProps extends PageProps {
  environment: string
  auth: {
    user: User
  }
  url: string
  flash: { bannerStyle: 'success' | 'error'; banner: string } | null
  sidebar_navigation: NavigationItem[]
  topbar_navigation: NavigationItem[]
}

export function usePage() {
  return usePageDefault<SharedProps>()
}

export function useEnvironment() {
  return computed(() => usePage().props.environment)
}

export function useIsEnvironment(environment: string) {
  return computed(() => useEnvironment().value === environment)
}

export function useUser() {
  return computed(() => usePage().props.auth.user)
}

export function useFlash() {
  return computed(() => usePage().props.flash)
}

export function useNavigation() {
  return computed(() => usePage().props.sidebar_navigation)
}
