import { route as ziggyRoute } from 'ziggy-js'

import type { Router, RouteName, RouteParams } from 'ziggy-js'

type RemoveStringFromUnion<T> = T extends T ? (string & {} extends T ? never : T) : never

export type StrictRouteName = RemoveStringFromUnion<RouteName>

export function route<T extends StrictRouteName>(
  name: T,
  parameters?: RouteParams<T>,
  absolute?: boolean,
): string
export function route(): Router

export function route<T extends StrictRouteName>(
  name?: T,
  parameters?: RouteParams<T>,
  absolute?: boolean,
): string | Router {
  if (name === undefined) return ziggyRoute()

  return ziggyRoute(name, parameters, absolute)
}
