<script lang="ts" setup>
import {
  TuiDropdown,
  TuiDropdownHeader,
  TuiDropdownItem,
  TuiDropdownItemGroup,
  TuiSidebar,
  TuiVerticalNav,
  TuiVerticalNavLink,
} from '@clickbar/tailwindui-vue'
import { Link } from '@inertiajs/vue3'

import AppLogo from '@/Components/AppLogo.vue'
import { route } from '@/Composables/route'
import { useUser } from '@/Composables/spaceship'

import type { NavigationItem, RouteName } from '@/Types'

import IconBolt from '~icons/heroicons/bolt'
import IconBuildingOffice from '~icons/heroicons/building-office'
import IconCalendarDays from '~icons/heroicons/calendar-days'
import IconFolder from '~icons/heroicons/folder'
import IconInbox from '~icons/heroicons/inbox'
import IconMap from '~icons/heroicons/map'
import IconWrenchScrewdriver from '~icons/heroicons/wrench-screwdriver'
import IHeroiconsOutlineViewGridAdd from '~icons/heroicons-outline/view-grid-add'
import IHeroiconsSolidChevronUpDown from '~icons/heroicons-solid/chevron-up-down'

defineOptions({
  inheritAttrs: false,
})

defineProps<{
  items: NavigationItem[]
}>()

const user = useUser()

const icons: Record<string, unknown> = {
  bolt: IconBolt,
  'building-office': IconBuildingOffice,
  'calendar-days': IconCalendarDays,
  folder: IconFolder,
  'wrench-screwdriver': IconWrenchScrewdriver,
  inbox: IconInbox,
  map: IconMap,
}

const userNavigationItems: {
  name: string
  routeName: RouteName
  method?: string
  as?: string
}[] = [
  {
    name: 'Profil',
    routeName: 'profile.show',
  },
  {
    name: 'Abmelden',
    routeName: 'logout',
    method: 'post',
    as: 'button',
  },
]
</script>

<template>
  <TuiSidebar class="relative h-full" color="primary" v-bind="$attrs">
    <div class="-mt-5 bg-white py-6 pr-4">
      <Link class="text-primary-600" :href="route('dashboard')">
        <AppLogo class="ml-6 h-auto w-24" />
      </Link>
    </div>

    <TuiVerticalNav class="mt-8 !px-6">
      <div v-for="item in items" :key="item.title">
        <TuiVerticalNavLink
          :label="item.title"
          type="inertia-link"
          :active="item.active && !item.children?.some((child) => child.active)"
          class="flex items-center"
          :href="item.children?.some((child) => child.active) ? '#' : item.url"
          :badge="item.attributes?.badge"
        >
          <template v-if="item.attributes.icon" #icon="iconProps">
            <Component :is="icons[item.attributes.icon]" v-bind="iconProps" />
          </template>

          <template v-else #icon="iconProps">
            <IHeroiconsOutlineViewGridAdd v-bind="iconProps" />
          </template>
        </TuiVerticalNavLink>

        <div
          v-if="item.children && (item.active || item.children.some((child) => child.active))"
          class="mt-1 flex justify-between"
        >
          <TuiVerticalNav class="flex-1 !px-0">
            <TuiVerticalNavLink
              v-for="child in item.children"
              :key="child.title"
              :label="child.title"
              type="inertia-link"
              :active="child.active"
              class="flex items-center pl-11"
              :href="child.url"
              :badge="child.attributes.badge"
            />
          </TuiVerticalNav>
        </div>
      </div>
    </TuiVerticalNav>

    <slot />

    <div class="flex-1" />

    <div v-if="user" class="mt-4 px-4">
      <!-- Profile dropdown -->
      <TuiDropdown placement="top-end" class="w-full">
        <template #activator>
          <button
            type="button"
            class="font-mediumtext-primary-100 group mx-1 w-full rounded-md px-3.5 py-2 text-left text-sm hover:bg-primary-700/75 focus:outline-none focus:ring-2 focus:ring-white active:bg-primary-800 active:text-white"
          >
            <span class="flex w-full items-center justify-between">
              <span class="flex min-w-0 items-center justify-between space-x-3">
                <span class="flex min-w-0 flex-1 flex-col">
                  <span class="truncate text-sm font-medium text-white">{{ user.name }}</span>
                </span>
              </span>

              <IHeroiconsSolidChevronUpDown
                class="ml-1 size-5 shrink-0 text-primary-200 group-hover:text-primary-300"
                aria-hidden="true"
              />
            </span>
          </button>
        </template>

        <TuiDropdownHeader :title="user.name" :value="user.email" />

        <TuiDropdownItemGroup>
          <TuiDropdownItem
            v-for="item in userNavigationItems"
            :key="item.name"
            type="inertia-link"
            :method="item.method"
            :as="item.as"
            :href="route(item.routeName)"
          >
            {{ item.name }}
          </TuiDropdownItem>
        </TuiDropdownItemGroup>
      </TuiDropdown>
    </div>
  </TuiSidebar>
</template>
